<template>
  <div>
    <modal-edit-day-percentage
      v-if="showModalEditDayPercentage"
      :showModal="showModalEditDayPercentage"
      :onClickCancel="closeEditModal"
    />

    <div>
      <label class="subtitle">
        <input
          type="checkbox"
          class="checkbox"
          @change="saveYieldSettings"
          v-model="mYieldSettings.ApplyDayPercentage"
          :checked="mYieldSettings.ApplyDayPercentage"
        />
        Use day percentage
      </label>
    </div>

    <article
      v-if="!mYieldSettings.ApplyDayPercentage"
      class="message is-info has-margin-top"
    >
      <div class="message-body">
        Activate if you want to apply a discount or surcharge on typical days of
        the week.
      </div>
    </article>

    <div class="has-text-right">
      <a
        v-if="mYieldSettings.ApplyDayPercentage"
        @click="setShowEditDayPercentage"
        class="is-size-7"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </span>
        <span>Edit</span>
      </a>
    </div>

    <table
      v-if="mYieldSettings.ApplyDayPercentage"
      class="table is-fullwidth is-striped is-narrow"
    >
      <tbody>
        <tr
          v-for="(dayPercentage, index) in yieldSettingsState.yieldSettings
            .DayPercentages"
          :key="index"
        >
          <td>
            <span>{{ dayPercentage.Day | getDayName }}</span>
          </td>
          <td>
            <span v-if="dayPercentage.Percentage !== 0"
              >{{ dayPercentage.Percentage }}%</span
            >
            <span v-if="dayPercentage.Percentage === 0">
              No discount or surcharge</span
            >
            <span v-if="dayPercentage.Percentage < 0"> discount</span>
            <span v-if="dayPercentage.Percentage > 0"> surcharge</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

const ModalEditYieldDayPercentage = () =>
  import('@/components/Yield/ModalEditYieldDayPercentage')

export default {
  components: {
    'modal-edit-day-percentage': ModalEditYieldDayPercentage,
  },

  data() {
    return {
      mYieldSettings: null,
      showModalEditDayPercentage: false,
    }
  },

  created() {
    this.mYieldSettings = JSON.parse(
      JSON.stringify(this.yieldSettingsState.yieldSettings)
    )
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsState']),
  },

  methods: {
    ...mapActions({ actionSaveYieldSettings: 'yieldStore/saveYieldSettings' }),

    saveYieldSettings() {
      let self = this

      self.yieldSettingsState.yieldSettings = self.mYieldSettings

      self.actionSaveYieldSettings(self.yieldSettingsState)
    },

    setShowEditDayPercentage() {
      this.showModalEditDayPercentage = true
    },

    closeEditModal() {
      this.showModalEditDayPercentage = false
    },
  },
}
</script>

<style></style>
